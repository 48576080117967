.App {
  position: relative;
  background-color: #000;
  min-height: 100vh; /* 100% of the viewport height */
  font-family: "eurostile", sans-serif;
  color: white;
  text-align: center;

  &.white-mode {
    background: aliceblue;
    color: black;
  }

  .section-home {
    height: 90vh;
    padding: 7rem 5rem 0 5rem;
  }

  .infinite-scroll-component__outerdiv {
    .infinite-scroll-component {
      overflow: hidden !important;
    }
  }

  .App-scroll {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    color: #fff;
    transition: transform 0.5s ease-in-out;

    .scroll-downs {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      width: 34px;
      height: 55px;

      .mousey {
        width: 3px;
        padding: 10px 15px;
        height: 30px;
        border: 2px solid #fff;
        border-radius: 25px;
        opacity: 0.75;
        box-sizing: content-box;

        &.white-mode {
          border-color: black;
        }

        .scroller {
          width: 3px;
          height: 10px;
          border-radius: 25%;
          background-color: #fff;
          animation-name: scroll;
          animation-duration: 2.2s;
          animation-timing-function: cubic-bezier(.15, .41, .69, .94);
          animation-iteration-count: infinite;

          &.white-mode {
            background: #000;
          }
        }

        @keyframes scroll {
          0% {
            opacity: 0;
          }
          10% {
            transform: translateY(0);
            opacity: 1;
          }
          100% {
            transform: translateY(15px);
            opacity: 0;
          }
        }
      }
    }
  }

  .App-scroll__animations {
    animation: slide-up 2s ease infinite;
  }

  @keyframes slide-up {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }

  .section-about {
    padding: 0 5rem 3rem 5rem;
  }

  .section-skills {
    padding: 3rem 5rem 3rem 5rem;
  }

  .section-project {
    padding:0 0 1rem 0;
  }

  @media (max-width: 768px) {
    .section-about {
      padding: 3rem 0;
    }

    .section-skills {
      padding: 3rem 0.5rem;
    }

    .section-project {
      padding: 3rem 0;
    }
  }

  @media (max-height: 768px) {
    .App-scroll {
      display: none;
    }
  }
}