$primary-color: #1d1d2a;
$navbar-height: 60px;

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  color: #fff;

  .navbar-logo--place {
    max-height: $navbar-height;

    .navbar-logo {
      max-height: $navbar-height;
    }
  }

  .navbar-right {
    display: flex;
    align-items: center;

    .navbar-controls {
      display: flex;
      gap: 20px;

      .navbar-resume-button {
        background-color: transparent;
        border: 1px solid #fff;
        color: #fff;
        padding: 8px 15px;
        cursor: pointer;
        margin-top: 15px;
        border-radius: 15px;
        font-size: 13px;
        &:hover {
          background-color: #fff;
          color: $primary-color;

          &.white-mode {
            background-color: black;
            color: white;
          }
        }

        &.white-mode {
          border-color: black;
          color: black;
        }
      }

      .navbar-language-toggle {
        margin-top: 15px;
      }
    }
  }
}