.home {
  display: flex;
  flex-direction: column;

  .home-header {
    margin: 0 0 15px;
  }

  .peter {
    padding: 5rem 0;
    width: 150px;
    height: 250px;
    margin: auto auto 15px;
  }

  .home-contact-button {
    margin: auto;
    background-color: #ff9c19;
    color: #fff;
    border: none;
    font-size: 13px;
    padding: 10px 20px;
    width: 8rem;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: background-color 0.3s;
  }

  .home-contact-button:hover {
    background-color: #d98507;
  }

  .contact-button:hover {
    background-color: azure;
    color: black;
  }

  .home-scroll-text {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    opacity: 0;
    animation: scrollUp 2s ease infinite alternate;
  }

  @keyframes scrollUp {
    0% {
      transform: translateX(-50%) translateY(0);
      opacity: 0.5;
    }
    100% {
      transform: translateX(-50%) translateY(-10px);
      opacity: 1;
    }
  }
}