label {
  width: 65px;
  height: 30px;
  position: relative;
  display: block;
  border-radius: 20px;
  box-shadow: inset 0 5px 15px rgba(0, 0, 0, 0.4), inset 0px -5px 15px rgba(255, 255, 255, 0.4);
  cursor: pointer;
  transition: 0.3s;

  .sun {
    position: absolute;
    left: 38.5px;
    top: 5px;
    fill: #fff;
    transition: 0.3s;
    z-index: 100;
  }

  .moon {
    position: absolute;
    left: 3px;
    top: 4px;
    fill: #7e7e7e;
    transition: 0.3s;
    z-index: 100;
  }
}

label::after {
  content: "";
  width: 25px;
  height: 25px;
  position: absolute;
  top: 3px;
  left: 3px;
  background: linear-gradient(180deg, #ffcc89, #d8860b);
  border-radius: 18px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  transition: 0.3s;
}

input {
  width: 0;
  height: 0;
  visibility: hidden;
}

// checked background color
input:checked + label {
  background: linear-gradient(180deg, #777, #3a3a3a);
}

input:not(:checked) + label {
  background: #ebebeb;
}

input:checked + label:after {
  left: 4px;
  background: #4b4b41;
}

input:not(:checked) + label:after {
  left: 61px;
  transform: translateX(-100%);
  background: linear-gradient(180deg, #ffcc89, #d8860b);
}

label, label:after {
  transition: 0.3s
}

label:hover:after {
  width: 35px;
}

label svg {
  top: 40px;
  z-index: 100;
}

input:checked + label svg.moon {
  fill: #fff;
}

input:checked + label svg.sun {
  fill: #4b4b41;
}