.project {
  display: grid;
  place-items: center;
  height: 40vh; /* Adjust this as needed */
  font-family: "eurostile", sans-serif;
  letter-spacing: 0.3px;

  .project-header {
    text-align: center;
    line-height: 1.125;
  }

  .project-item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%; /* Adjust the width as needed */
    padding: 20px;
    background-color: #fff; /* Background color of the work item */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    text-align: center;
    overflow: hidden; /* Ensure content does not overflow the item */
    transition: transform 0.25s ease-in-out;

    .logo-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    .item-image {
      max-width: 300px;
      height: auto;
      border-radius: 8px;
      transition: opacity 0.3s ease;
    }

    .info-container {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ff9c19;
      color: #fff;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 20px;
      opacity: 0;
      transition: opacity 0.3s ease;
      text-align: center;
    }

    .info-text {
      padding: 0 3rem 1rem;;
      margin: 0;
    }

    .visit-button {
      padding: 10px 20px;
      border: none;
      border-radius: 8px;
      cursor: pointer;
      background-color: #f0f0f0;

      .visit-button__icon {
        width: 16px;
        vertical-align: text-bottom;
      }
    }

    .visit-button:hover {
      background-color: #e0e0e0;
    }

    &:hover .item-image {
      opacity: 0;
    }

    &:hover .info-container {
      opacity: 1;
    }
  }

  .project-item a {
    display: block;
    width: 100%;
    height: 100%;
    text-decoration: none;
    color: inherit;
  }

  @media (max-width: 768px) {
    height: 30vh;
    .project-item {
      width: 80%;
    }
  }
}
