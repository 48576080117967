.about {
  display: flex;
  flex-direction: column;

  .about-text {
    margin: 0 0 15px;
    line-height: 1.5;
    padding: 0 20%;
    text-align: left;

    .about-text--body {
      font-size: 18px;
    }
  }

  @media (max-width: 768px) {
    .about-text {
      padding: 0 10%;
    }
  }
}

