.language-toggle {
  display: flex;
  align-items: center;
  margin-left: auto; /* This will push it to the right */
  width: 60px;
  height: 40px;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.4s;

  .flag {
    width: 60px;
    height: 40px;
    margin: 0;
    display: inline-block;
    border-radius: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  .flag.tr {
    position: relative;
    background: url('../../img/turkish-flag.jpg') 50% 75%/cover;
    background-size: 70%;
  }

  .flag.en {
    position: relative;
    background: url('../../img/english-flag.png') center/cover;
    background-size: 270%;
  }
}

.language-toggle.turkish {
  background-color: #fff;
}

.language-toggle.english {
  background-color: #fff;
}
