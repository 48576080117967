$footer-height: 60px;

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem 1rem;
  background-color: #ff9c19;
  color: black;

  .footer__left {
    max-height: $footer-height;
    .footer-logo {
      max-height: $footer-height;
    }
  }

  .footer__center{
    margin-left: 13%;
    p {
      opacity: 0.8;
      margin: 0;
      .footer__center-copyright-icon {
        vertical-align:middle;
      }
    }
  }

  .footer__right {
    display: flex;
    flex-direction: row;
    margin-right: 65px;

    .footer-item {
      transition: background-color 0.4s;
      padding: 0 0.7rem;
      cursor: pointer;
      display: flex;
      align-items: center;
      height: 40px;
      border: 2px solid black;
      border-radius: 50%;
      margin-right: 2px;
      .footer-href {
        .footer-href__image {
          width: 20px;
          transition: filter 0.4s; // SVG renginde geçiş efekti için
          filter: grayscale(100%); // SVG rengini desaturate et (siyah beyaza dönüştür)
        }
        &:hover .footer-href__image {
          filter: none;
        }
      }

      &:hover .footer-href__image {
        filter: none;
      }
    }

    &:hover .footer-href__image {
      filter: none;
    }

    .footer-item:hover {
      background-color: black;
      svg path {
        fill: white !important;
      }
      a {
        svg path {
          fill: white !important;
        }
      }

    }
  }
  @media (max-width: 768px) {
    display: grid;
    justify-content: center;
    .footer__left {
      display: none;
    }

    .footer__center {
      margin: 0 0 10px;
    }

    .footer__right {
      justify-content: center;
      margin-right: 0;
    }
  }
}