.skills {
  max-width: 1344px;
  margin: auto;

  .skills-box {
    padding: 0;
    background-color: #ffffff;
    border-radius: 12px;
    color: #000;

    &.white-mode {
      border-color: black;
      background: #383838;
      color: white;
    }

    .skills-box--columns {
      display: flex;
      margin-right: 0;
      margin-left: 0;
      justify-content: center;

      .skills-box__content:first-child {
        border-right: 1px solid #E6ECF8;
      }

      .skills-box__content {
        width: 100%;
        padding: 70px 45px;

        h1 {
          font-weight: 500;
        }

        .skills-box__content--icon {
          margin: auto;
          padding: 1rem;
          width: fit-content;
          background-color: #5BE9B9;
          border-radius: 50%;
        }

        .skills-box__content--text {
          font-size: 18px;
          padding: 0 10% 0 10%;
          margin-bottom: 2rem;
        }

        .skills-box__content--header {
          color: #ff9c19;
        }

        .skills-box__content--programming-languages {
          margin-bottom: 2rem;
        }

        ul {
          list-style: none;
          padding-inline-start: 0;
          line-height: 1.5;
        }
      }
    }

    @media (max-width: 768px) {
      .skills-box--columns {
        display: block;

        .skills-box__content:first-child {
          border-right: transparent;
          border-bottom: 1px solid #E6ECF8;
        }

        .skills-box__content {
          padding: 35px 0;
        }
      }
    }
  }

  .skills-resume {
    margin-top: 8rem;
    width: 8rem;
    height: 3rem;
    border-radius: 20px;
    border-color: #5BE9B9;
    background-color: #5BE9B9;
    font-size: 15px;
    cursor: pointer;
  }
}