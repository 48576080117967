.sidebar {
  position: absolute;
  left: 0;
  top: 23rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ff9c19;
  border-radius: 0 5px 5px 0;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
  padding: 0.7rem 3px 0.7rem 3px;

  .sidebar-item {
    transition: background-color 0.4s;
    padding: 0.7rem 0 0.7rem 0;
    border-radius: 8px;
    .sidebar-href {
      text-decoration: none;
      padding: 1rem;
      .sidebar-href__image {
        width: 30px;
      }
    }
  }

  .sidebar-item:hover {
    background-color: #d98507;
  }

  @media (max-width: 1024px) {
    display: none;
  }
}
